<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="editMaterial('new')"
              >
                <span class="text-nowrap">Crear Material</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMaterialesListTable"
        class="position-relative"
        :items="fetchMateriales"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchMateriales"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Codigo -->
        <template #cell(codigo)="data">
          <b-link
            :id="`${data.item.estado === 'inactivo' ? 'color-inactivo' : ''}`"
            class="font-weight-bold d-block text-nowrap"
            @click="editMaterial(data.item.id)"
          >
            #{{ data.item.codigo }}
          </b-link>
        </template>
        <!-- Column: Descripción -->
        <template #cell(descripcion)="data">
          {{ data.item.descripcion_custom ? data.item.descripcion_custom : data.item.descripcion }}
        </template>

        <!-- Column: Coste -->
        <template #cell(coste)="data">
          <span>{{ data.item.coste }}€</span>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <!-- Editar -->
            <b-button
              :id="`edit-${data.item.id}`"
              variant="success"
              class="btn-icon mr-05"
              @click="editMaterial(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Editar</b>
            </b-tooltip>
            <!-- Desactivar -->
            <div v-if="data.item.estado != 'inactivo'">
              <b-button
                :id="`desactivar-${data.item.id}`"
                variant="warning"
                class="btn-icon mr-05"
                @click="statusMaterial(data.item.id, 'inactivo')"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
              <b-tooltip
                :target="`desactivar-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Desactivar</b>
              </b-tooltip>
            </div>

            <!-- Activar -->
            <div v-if="data.item.estado == 'inactivo'">
              <b-button
                :id="`activar-${data.item.id}`"
                variant="info"
                class="btn-icon mr-05"
                @click="statusMaterial(data.item.id, 'activo')"
              >
                <feather-icon icon="PlayCircleIcon" />
              </b-button>
              <b-tooltip
                :target="`activar-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Activar</b>
              </b-tooltip>
            </div>

            <!-- Eliminar -->
            <div v-if="checkIfItemCanBeDeleted(data.item)">
              <b-button
                :id="`delete-${data.item.id}`"
                variant="danger"
                class="btn-icon"
                @click="
                  deleteModal = true;
                  selectedItem = data.item;
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-tooltip
                :target="`delete-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Eliminar</b>
              </b-tooltip>
            </div>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMateriales"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el material."
      title="¿Está seguro de que desea eliminar el material?"
      @action="deleteMaterial(selectedItem.id)"
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import useMaterialesList from './useMaterialesList'
import materialesStoreModule from './materialesStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      deleteModal: false,
      selectedItem: {},
    }
  },
  methods: {
    checkIfItemCanBeDeleted(item) {
      return !(item.albaranes_detalles_count > 0
      || item.materiales_recepcions_count > 0
      || item.partes_de_trabajo_materiales_count > 0
      || item.pedido_detalles_count > 0
      || item.pedidos_proveedores_count > 0)
    },
    editMaterial(id) {
      this.$router.push({
        name: 'material-edit',
        params: {
          id,
        },
      })
    },
    deleteMaterial(id) {
      this.$store.dispatch('app-materiales/deleteMaterial', id).then(() => {
        this.deleteModal = false
        this.refetchData()
      })
    },
    statusMaterial(id, status) {
      this.$store
        .dispatch('app-materiales/changeStatus', { id, status })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'

    // Register module
    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)
    })

    const {
      fetchMateriales,
      tableColumns,
      perPage,
      currentPage,
      totalMateriales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialesListTable,
      refetchData,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useMaterialesList()

    return {
      fetchMateriales,
      tableColumns,
      perPage,
      currentPage,
      totalMateriales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialesListTable,
      refetchData,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
#color-inactivo {
  color: #888;
  text-decoration: line-through;
}
.mr-05 {
  margin-right: 5px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
